import React, { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './tabs.module.scss';
import TabHeader from './TabHeader';
import TabPanel from './TabPanel';

const TabContext = createContext();

const Tabs = ({ tabs, children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const switchTab = useCallback(index => {
    setActiveTab(index);
  }, []);

  return (
    <TabContext.Provider value={{ activeTab, switchTab }}>
      <div className={styles.tabsContainer}>
        <TabHeader tabs={tabs} />
        <TabPanel>{children}</TabPanel>
      </div>
    </TabContext.Provider>
  );
};

// Custom Hook to Access Tab Context
export const useTabs = () => useContext(TabContext);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(Tabs);
