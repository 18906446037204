import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Card } from '@components';
import propTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './BarChart.module.scss';
import defaultChartColors from '../chart-colors';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const BarChart = ({
  title,
  header,
  legendConfig = {
    display: false,
  },
  chartData,
}) => {
  const { labels, barTitle, backgroundColors, data } = chartData;
  const chartDataSets = data.map((dataSet, index) => ({
    label: barTitle[index],
    data: dataSet,
    backgroundColor: backgroundColors ? backgroundColors[index] : defaultChartColors,
    borderRadius: 4,
    borderWidth: 1,
    borderAlign: 'inner', // Ensure the border appears inside the chart for clarity
    shadowBlur: 1,
    shadowColor: 'rgba(0, 0, 0, 0.1)', // Dark shadow for depth
  }));

  const preparedChartData = {
    labels,
    datasets: chartDataSets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: legendConfig,
      datalabels: {
        color: '#FFF',
        anchor: 'top',
        align: 'top',
        borderRadius: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        clip: true,
        offset: 10,
        padding: {
          left: 6,
          right: 6,
          top: 2,
          bottom: 2,
        },
        font: {
          size: 12,
          weight: 'bold',
          lineHeight: 1.2,
        },
        // Formatter to hide labels for zero values
        formatter: value => {
          return value === 0 ? null : `${value}`; // Hide zero values
        },
      },
      afterDatasetsDraw: chart => {
        const { ctx } = chart;
        const meta = chart.getDatasetMeta(0); // Access the first dataset
        ctx.save();
        ctx.strokeStyle = '#FF0000'; // Set diagonal line color
        ctx.lineWidth = 10; // Set diagonal line width

        // Draw diagonal lines over each bar
        meta.data.forEach(bar => {
          const { x, y, width, height } = bar.getProps(['x', 'y', 'width', 'height'], false);
          ctx.beginPath();
          ctx.moveTo(x, y); // Start at the top-left corner of the bar
          ctx.lineTo(x + width, y + height); // Draw to the bottom-right corner of the bar
          ctx.stroke();
        });

        ctx.restore(); // Restore the context state
      },
    },
    scales: {
      x: {
        stacked: true, // Enable stacking for the x-axis
      },
      y: {
        stacked: true, // Enable stacking for the y-axis
        suggestedMin: 1, // Minimum y-axis value
        min: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
    animation: {
      duration: 2000, // Set animation duration to 1000 milliseconds (1 second)
    },
  };

  return (
    <Card>
      <div className={styles.salesChart}>
        <div className={styles.chartHeader}>
          <span className={styles.chartTitle}>{title}</span>

          {/* Top Right Sales Info */}
          {header ? (
            <div className={styles.salesInfo}>
              <h2>
                {`${header.value} `}
                {header.difference ? (
                  <span
                    className={
                      styles.percentage +
                      (header.difference.startsWith('-') ? ` ${styles.negative}` : '')
                    }
                  >
                    {header.difference}
                  </span>
                ) : null}
              </h2>
              {header.label ? <p>{header.label}</p> : null}
            </div>
          ) : null}
        </div>
        {/* Sales Bar Chart */}
        <div className={styles.barChart}>
          <Bar data={preparedChartData} options={options} />
        </div>
      </div>
    </Card>
  );
};

BarChart.propTypes = {
  title: propTypes.string,
  header: propTypes.object,
  legendConfig: propTypes.object,
  chartData: propTypes.object,
};

export default BarChart;
