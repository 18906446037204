import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import style from './dropdown.module.scss';

const Dropdown = ({ options, defaultOption, onOptionSelected, message }) => {
  const [isOpen, setIsOpen] = useState(false); // To track the dropdown visibility
  const [selectedOptions, setSelectedOptions] = useState([]); // To store the selected option

  // Reset selected options when options change
  useEffect(() => {
    // Filter selected options that are still present in the new options list
    setSelectedOptions(selectedOptions =>
      selectedOptions.filter(selectedOption =>
        options.some(option => option.id === selectedOption.id),
      ),
    );
    setIsOpen(false);
  }, [options]);

  // Memoize formatted selected options
  const formattedSelectedOptions = useMemo(() => {
    return selectedOptions.map(option => option.name).join(', ');
  }, [selectedOptions]);

  // Toggle the dropdown visibility
  const toggleDropdown = () => setIsOpen(prevState => !prevState);

  // Handle selection of an item
  const handleSelect = useCallback(
    newOption => {
      setSelectedOptions(prevSelectedOptions => {
        const exists = prevSelectedOptions.some(item => item.id === newOption.id);
        const updatedOptions = exists
          ? prevSelectedOptions.filter(option => option.id !== newOption.id)
          : [...prevSelectedOptions, newOption];

        onOptionSelected(updatedOptions);
        return updatedOptions;
      });
      setIsOpen(false); // Close dropdown after selecting an option
    },
    [onOptionSelected],
  );

  const isSelected = id => selectedOptions.some(item => item.id === id);

  return (
    <div className={style.container}>
      {/* Button to toggle dropdown */}
      <button
        onClick={toggleDropdown}
        className={`${style.dropdownSelector} ${isOpen ? style.open : ''}`}
      >
        <span>{formattedSelectedOptions || defaultOption || 'Select an option'}</span>
        <img src="/images/arrow_down.svg" alt="Selector" />
      </button>

      {message && <span className={style.warningMessage}>{message}</span>}

      {/* Dropdown menu */}
      <ul className={`${style.dropdownMenu} ${isOpen ? style.open : ''}`}>
        {options.map(option => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
          <li
            key={option.id} // Use option.id as the key to ensure better performance
            className={`${style.dropdownItem} ${isSelected(option.id) ? style.selected : ''}`}
            onClick={() => handleSelect(option)}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultOption: PropTypes.string,
  onOptionSelected: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default Dropdown;
