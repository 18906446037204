import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTabs } from './Tabs';
import styles from './tabs.module.scss';

const TabPanel = ({ children }) => {
  const { activeTab } = useTabs();
  const cachedChildren = useMemo(() => {
    return React.Children.toArray(children); // Convert children to an array for better handling
  }, [children]);

  return <div className={styles.tabContent}>{cachedChildren[activeTab]}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(TabPanel);
