import React from 'react';
import PropTypes from 'prop-types';
import { useTabs } from './Tabs';
import styles from './tabs.module.scss';

const TabHeader = ({ tabs }) => {
  const { activeTab, switchTab } = useTabs();

  return (
    <div className={styles.tabsHeader}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={activeTab === index ? styles.activeTab : styles.tab}
          onClick={() => switchTab(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

TabHeader.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(TabHeader);
